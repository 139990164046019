import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Fade from "react-reveal/Fade"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Metaverse = () => {
  return (
    <>
      <Layout>
        <Seo title="Unlocking the Potential Of The Metaverse" />
        <Fade top>
          <div className="py-10 lg:py-12 bg-dusk"></div>
        </Fade>
        <div className="py-24">
          <div className="xl:pr-96">
            <div className="container mx-auto text-dusk">
              <div>
                <div className="text-5xl font-firs-medium">
                  Unlocking the Potential Of The Metaverse:
                  {/* <div> Smart Textiles</div> */}
                </div>
                <div className="text-2xl mt-4">
                  Nivantha Bandara – Program Manager, Softmatter
                  {/* <div>and smart textiles</div> */}
                </div>
                <div className="mt-4">16th August 2022</div>
              </div>
              <div className=" my-7  py-10">
                <div>
                  <StaticImage
                    alt="Softmatter"
                    src="../../images/news/blog_metavers.jpg"
                  />
                </div>
                <div className="my-4">
                  Why should we be excited about the rising popularity of the
                  integration between virtual and physical spaces?
                </div>
                <div className="mb-4">
                  Virtual shopping malls, unlimited creative potential for
                  creators, better insights for product development and smelling
                  the virtual world.
                </div>
                <div className="mb-4">
                  These are just a few of the ways that the Metaverse could
                  revolutionize the world as it is. While it is quite early to
                  know for sure if the Metaverse would do so, companies could
                  prepare for such a future, where the virtual and physical
                  realities come together.
                </div>
                <div className="mb-4">
                  In a backdrop of data security concerns originating from Web
                  2.0, countless new opportunities have been presented by Web
                  3.0. In tandem with the Metaverse, the internet would be
                  democratized such that users would essentially own their data,
                  greatly simplifying the process of monetizing content
                  generated.
                </div>
                <div className="mb-4">
                  In this new era, the Metaverse allows for the apparel industry
                  to drive immersive experiences using virtual reality
                  technologies.{" "}
                </div>

                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Emergence of Web 3.0
                  </div>
                  <div className="mb-4">
                    How did the need for Web 3.0 arise? This is important to
                    consider, as it helps understand the opportunities that are
                    available in Web 3.0.
                  </div>
                  <div className="mb-4">
                    The current age of the internet, referred to as Web 2.0, has
                    fostered a culture of distrust due to the lack of
                    transparency and data security, particularly concerning
                    user-generated content. Due to the centralized nature in
                    which the internet currently operates, social media
                    companies such as Instagram, Facebook and YouTube have the
                    freedom to ban users and restrict content; hence users have
                    limited control over their online personas and digital
                    assets. Therefore, barriers to earn revenue on
                    user-generated content where users may lack control of their
                    own content, is a major source of frustration. Furthermore,
                    the current centralized aspect of the internet allows large
                    corporate entities to profit using valuable user data. An
                    example of an incident which led to the skepticism towards
                    entities such as Facebook, is the data misuse scandal, in
                    which, personal data of Facebook users was collected by a
                    consulting firm Cambridge Analytica, for political
                    targeting.
                  </div>
                  <div className="mb-4">
                    However, as the internet continues to evolve, Web 3.0
                    emerges, and may be understood as an ongoing iteration that
                    operates on a decentralized online network, which offers
                    users the ability to take ownership of their own data and
                    other digital assets. An example of decentralized protocols,
                    used by Web 3.0, are blockchains, which are leveraged for
                    cryptocurrency transactions. The adoption of Web 3.0 grants
                    users the opportunity to monetize their creations, while
                    owning the process end-to-end, without requiring permission
                    from big tech companies. Further, with the advancement of
                    artificial intelligence, predictive analytics can be
                    deployed on the user’s end to distinguish between legitimate
                    and fraudulent actions, thus providing users with accurate
                    information for decision-making.
                  </div>
                  <div className="mb-4">
                    The use of Web 3.0 technology together with the Metaverse,
                    will enable companies to expand to a new domain in the
                    internet environment, thus bringing about a multitude of
                    revenue-generating opportunities.
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Entering the Metaverse with the freedom offered by Web 3.0
                  </div>
                  <div className="mb-4">
                    The Metaverse is inherently a network of 3D worlds, that
                    integrates virtual and physical spaces, regardless of
                    geographical location. The applications of the Metaverse
                    include digital assets, healthcare, online shopping, social
                    media, and gaming to name a few. This opens a world of
                    opportunities for creators to generate a new type of content
                    in each of these domains. Facebook’s announcement of
                    entering the Metaverse brought the concept of Metaverse to
                    mainstream consciousness.
                  </div>
                  <div className="mb-4">
                    The ingredients for building certain Web 3.0 applications
                    include the following: augmented reality, virtual reality,
                    and holographic projections, highlighting an overlap with
                    the Metaverse. It could be utilized in parallel with Web 3.0
                    using blockchain systems to operate within a network of
                    computers. Therefore, Web 3.0 could serve its purpose of
                    connecting the Metaverse, whilst adding the benefits of data
                    security and democratization of the internet. Furthermore,
                    the Metaverse and Web 3.0 could work harmoniously to present
                    new opportunities for creators to monetize their virtual
                    content.
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">
                    Opportunities for apparel in the realm of the Metaverse
                  </div>
                  <div className="mb-4">
                    With the emergence of the Metaverse, companies can not only
                    provide their products and services to anyone in the world,
                    but also enable consumers to experience the use of such
                    products and services through the integration of physical
                    and virtual reality. Such global expansion could be done at
                    a lower cost, making the Metaverse an appealing concept to
                    organizations. In particular, there is a plethora of
                    opportunities for the apparel industry.
                  </div>
                  <div className="mb-4">
                    One such opportunity is virtual fashion, which is an
                    application of the integration of apparel and the Metaverse.
                    A study by Morgan Stanley indicates that virtual fashion
                    could be a $50 billion industry by 2030. This is primarily
                    due to large apparel brands partaking in the sale of
                    Non-Fungible Tokens (NFTs) and the rising popularity of
                    events such as Metaverse Fashion Week. While large luxury
                    fashion brands such as Gucci and Dolce & Gabbana have
                    launched their own virtual clothing lines on the Metaverse,
                    Ralph Lauren has already sold 100k units from their
                    collection of wearables. Due to these reasons, the Metaverse
                    is viewed as a lucrative market for traditional apparel
                    brands. For designers, it unlocks creative freedom in the
                    digital realm that may produce an entirely new generation of
                    creators. Experimentation through the interaction of virtual
                    fashion design and physical manufacturing accentuates the
                    creative potential of designers.
                  </div>
                  <div>
                    <StaticImage
                      alt="Softmatter"
                      src="../../images/news/metaverse/Mclkinsey.jpg"
                    />
                  </div>
                  <div className="mb-4 mt-4">
                    <div>
                      Source :{" "}
                      <a
                        href="https://www.mckinsey.com/industries/retail/our-insights/how-the-fashion-industry-can-get-into-a-metaverse-mindset"
                        target="_blank"
                        className="text-mint"
                      >
                        www.mckinsey.com/industries/retail/our-insights/how-the-fashion-industry-can-get-into-a-metaverse-mindset
                      </a>
                    </div>
                  </div>
                  <div className="mb-4">
                    Further, companies can use innovative ways of advertising,
                    being consumer-centric in their approach. Whilst traditional
                    digital marketing has been in the form of targeted
                    marketing, the Metaverse allows to focus more on the
                    consumer experience. Companies such as Coca Cola have used
                    this consumer-centric approach for auctions, which contained
                    several NFTs, including a themed bubble jacket that the
                    winning bidder could wear in the virtual world,
                    Decentraland. This is a powerful example of creating a rich
                    consumer experience. In the apparel industry, this
                    consumer-centric approach, which uses the apparel in real
                    time, would create greater consumer satisfaction, as opposed
                    to being bombarded with targeted adverts and promotions.
                  </div>
                  <div className="mb-4">
                    Accessing the Metaverse is done through virtual reality
                    interfaces. To enhance immersion and therefore, adoption of
                    the Metaverse, hardware development focusing on refining
                    virtual reality headset capabilities by integrating apparel
                    with sensors and haptic technology continues to be a key
                    driver. Adoption will continue to rise due to the production
                    of cheaper virtual reality headsets. OVR Technology is an
                    example of a company that has pushed the boundaries of
                    immersive experience in the virtual world by developing
                    scent technology. Their INHALE system utilizes an olfactory
                    headset, which offers wellness experiences that promote
                    relaxation and mental fitness, through scent ware cartridges
                    to match the state of mind. Further, the data collection
                    capabilities of VR headsets allow companies to tailor-make
                    their apparel products by better understanding consumer
                    interaction. VR-powered testing through body language
                    recordings can be deployed to analyze consumer behavior and
                    gain better insights for product development.
                  </div>
                  <div className="mb-4">
                    This article considered the issues with the current
                    technology, Web 2.0, and the emergence of Web 3.0 along with
                    the Metaverse. There is significant research to show how
                    several giants in the apparel industry have already begun to
                    try out this concept. There is prediction of a growing
                    demand for the Metaverse. While it is still too early to
                    know for sure if this would be a successful concept, the
                    growing interest shown by prominent players in the apparel
                    industry seems to show that the Metaverse could be here to
                    stay!
                  </div>
                </div>
                <div className="mb-4 pt-5">
                  <div className="font-firs-semibold mb-3">References</div>
                  <div className="mb-4">
                    <a
                      href="https://ovrtechnology.com/"
                      target="_blank"
                      className="text-mint"
                    >
                      1. OVR Technology lets you smell the Metaverse
                    </a>
                  </div>
                  <div className="mb-4">
                    <a
                      href="https://www.blockchain-council.org/metaverse/web-3-0-vs-metaverse/"
                      target="_blank"
                      className="text-mint"
                    >
                      2. Web 3.0 vs. Metaverse: A detailed comparison
                    </a>
                  </div>
                  <div className="mb-4">
                    <a
                      href="https://island.lk/metaverse-or-meta-averse-exploring-the-implications-of-virtual-fashion-for-sri-lankan-apparel-irl/"
                      target="_blank"
                      className="text-mint"
                    >
                      3. Metaverse or meta-averse? Exploring the implications of
                      virtual fashion for Sri Lankan apparel IRL
                    </a>
                  </div>
                  <div className="mb-4">
                    <a
                      href="https://medium.datadriveninvestor.com/the-insane-future-of-web-3-0-and-the-metaverse-4cec3f13895a"
                      target="_blank"
                      className="text-mint"
                    >
                      4. The Insane Future of Web 3.0 and the Metaverse
                    </a>
                  </div>
                  <div className="mb-4">
                    <a
                      href="https://www.youtube.com/watch?v=JIukjje5XAA"
                      target="_blank"
                      className="text-mint"
                    >
                      5. Understanding the Metaverse and Web 3.0
                    </a>
                  </div>
                  <div className="mb-4">
                    <a
                      href="https://www.forbes.com/sites/bernardmarr/2021/12/07/the-7-biggest-consumer-technology-trends-in-2022/"
                      target="_blank"
                      className="text-mint"
                    >
                      6. the-7-biggest-consumer-technology-trends-in-2022
                    </a>
                  </div>
                  <div className="mb-4">
                    <a
                      href="https://www.twice.com/retailing/retail/the-top-10-consumer-technology-trends-that-will-dominate-in-2022"
                      target="_blank"
                      className="text-mint"
                    >
                      7.
                      the-top-10-consumer-technology-trends-that-will-dominate-in-2022
                    </a>
                  </div>
                  <div className="mb-4">
                    <a
                      href="https://www.twice.com/retailing/retail/the-top-10-consumer-technology-trends-that-will-dominate-in-2022"
                      target="_blank"
                      className="text-mint"
                    >
                      7.
                      the-top-10-consumer-technology-trends-that-will-dominate-in-2022
                    </a>
                  </div>
                  <div className="mb-4">
                    <a
                      href="https://businesstechguides.co/importance-of-metaverse-for-businesses"
                      target="_blank"
                      className="text-mint"
                    >
                      8. Why should businesses care about the Metaverse
                    </a>
                  </div>

                  {/* <div className="mt-20">
                    <div className="grid sm:grid-cols-2">
                      <div className="">
                        <StaticImage
                          alt="Softmatter"
                          src="../../images/news/Post3.png"
                        />
                        <div className="mb-2 text-sm mt-4 text-salmon font-firs-medium">
                          Evolved from a futuristic accessory to a ubiquitous
                          health and wellness wearable:
                        </div>
                        <div className="text-sm mb-2">
                          Sep 15, 2021 | Health and wellness Wearables,
                          Engineered Knit, Product Engineering
                        </div>
                        <div className="text-sm mb-2">
                          What factors will gear more consumers to be more
                          comfortable in having a virtual health assistant
                          strapped to their wrist?
                        </div>
                        <div className="text-sm mt-2 text-salmon font-firs-medium">
                          <Link to="/news">
                            <span className="hover:underline cursor-pointer">
                              Read More
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Metaverse
